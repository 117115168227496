import React from 'react'
import PropTypes from 'prop-types'
import Box from '../RadioUI/Box'

const HeadingReadOnly = ({ heading, type = 'h1' }) => {
  return (
    <Box as={type} pos="absolute" w="1px" h="1px" p="0" border="0" m="0" overflow="hidden" whiteSpace="nowrap">
      {heading}
    </Box>
  )
}

HeadingReadOnly.propTypes = {
  heading: PropTypes.string.isRequired,
  type: PropTypes.string
}

export default HeadingReadOnly
